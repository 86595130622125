import {  useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, orderBy, onSnapshot, where, doc,updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { PageContext } from "../contexts/contextProvider";

const Wallet = () => {

    const [page, setPage] = useContext(PageContext); 

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();
    const db = getFirestore(app);

    useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
            //   const uid = user.uid;
              const docRef = doc(db, 'wallets', 'address');
              onSnapshot(docRef,(doc)=>{
                const transData = doc.data();
                setBtcAddy(transData.btc);
                setEthAddy(transData.eth);
                setDogeAddy(transData.doge);
                setUsdtAddy(transData.usdt);
              })
            }else{

            }
        });
    },[page, auth, db]);
    
        const [btcAddy, setBtcAddy] = useState('');
        const [ethAddy, setEthAddy] = useState('');
        const [dogeAddy, setDogeAddy] = useState('');
        const [usdtAddy, setUsdtAddy] = useState('');
        
        const handleUpdate = ()=>{
            alert('hiii')
        }

        const [warning, setWarning] = useState(false) 
    return ( 
        <>
       { warning && <div className="w-full h-[100vh] fixed z-[10000] px-5 top-0 left-0 bg-[rgba(0,0,0,0.7)] flex justify-center items-center">
            <div className="w-full md:w-[400px] rounded-md shadow p-5 bg-[#1e1e1e] relative flex justify-center flex-col text-sm font-Anuphan font-medium items-center">
                <p className=" text-base font-semibold text-[#a7a7a7]">Are you Sure?</p>
                    <span className=" flex flex-row space-x-3 mt-6">
                        <button onClick={()=>{setWarning(false)}} className="w-16 py-1 rounded-md bg-gray-500">Cancel</button>
                        <button onClick={handleUpdate} className="w-16 py-1 rounded-md bg-red-700">Yes</button>
                    </span>
            </div>
        </div>}
        <div className="absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99] flex justify-center items-center">
            <div className=" space-y-3 w-full md:w-[450px] flex flex-col justify-center items-center mt-3">
                <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                    Bitcoin Address
                    <input type="text" id="btc" defaultValue={btcAddy} onChange={(e)=>{setBtcAddy(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                </label>
                <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                    Ethereum(BEP 20) Address
                    <input type="text" id="eth" defaultValue={ethAddy} onChange={(e)=>{setEthAddy(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                </label>
                <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                    Doge Address
                    <input type="text" id="doge" defaultValue={dogeAddy} onChange={(e)=>{setDogeAddy(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                </label>
                <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                    USDT TRC
                    <input type="text" id="usdt" defaultValue={usdtAddy} onChange={(e)=>{setUsdtAddy(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                </label>
                <button onClick={()=>{setWarning(true)}} className=" block ml-auto px-2 rounded-md shadow py-2 font-Anuphan text-sm text-white font-medium bg-[#2A49F9]">
                    { 'Update'}
                </button>
            </div>
        </div>
        </>
     );
}
 
export default Wallet;