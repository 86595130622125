import {  useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import plus from '../images/cancel.png';
import { getFirestore, collection, query, orderBy, onSnapshot, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { CountryContext, CryptoCommisionFeeContext, CryptoDepositContext, CryptoProfitsContext, CryptoTotalbalContext, CryptoUpgradeFeeContext, CryptoWithdrawFeeContext, DocidContext, EditUserContext, FeatCommisionFeeContext, FeatDepositContext, FeatProfitsContext, FeatTotalbalContext, FeatUpgradeFeeContext, FeatWithdrawFeeContext, ForexCommisionFeeContext, ForexDepositContext, ForexProfitsContext, ForexTotalbalContext, ForexUpgradeFeeContext, ForexWithdrawFeeContext, MailContext, NameContext, PageContext, PasswordContext, PhoneContext, PlanContext, StockDepositContext, StockProfitsContext, StockTotalbalContext, StocksCommisionFeeContext, StocksUpgradeFeeContext, StocksWithdrawFeeContext } from "../contexts/contextProvider";

const UserDash = () => {
    const [editUser, setEditUser] = useContext(EditUserContext);
    const [page, setPage] = useContext(PageContext); 
    const [docid, setDocid] = useContext(DocidContext);
    const [Name, setName] = useContext(NameContext);
    const [Mail, setMail] = useContext(MailContext);
    const [Country, setCountry] = useContext(CountryContext);
    const [Phone, setPhone] = useContext(PhoneContext);
    const [Password, setPassword] = useContext(PasswordContext);
    const [Plan, setPlan] = useContext(PlanContext);
    const [FeatDeposit, setFeatDeposit] = useContext(FeatDepositContext);
    const [FeatProfits, setFeatProfits] = useContext(FeatProfitsContext);
    const [FeatTotalbal, setFeatTotalbal] = useContext(FeatTotalbalContext);
    const [FeatUpgradeFee, setFeatUpgradeFee] = useContext(FeatUpgradeFeeContext);
    const [FeatWithdrawFee, setFeatWithdrawFee] = useContext(FeatWithdrawFeeContext);
    const [FeatCommisionFee, setFeatCommisionFee] = useContext(FeatCommisionFeeContext);
    const [ForexDeposit, setForexDeposit] = useContext(ForexDepositContext);
    const [ForexProfits, setForexProfits] = useContext(ForexProfitsContext);
    const [ForexTotalbal, setForexTotalbal] = useContext(ForexTotalbalContext);
    const [ForexUpgradeFee, setForexUpgradeFee] = useContext(ForexUpgradeFeeContext);
    const [ForexWithdrawFee, setForexWithdrawFee] = useContext(ForexWithdrawFeeContext);
    const [ForexCommisionFee, setForexCommisionFee] = useContext(ForexCommisionFeeContext);
    const [CryptoDeposit, setCryptoDeposit] = useContext(CryptoDepositContext);
    const [CryptoProfits, setCryptoProfits] = useContext(CryptoProfitsContext);
    const [CryptoTotalbal, setCryptoTotalbal] = useContext(CryptoTotalbalContext);
    const [CryptoUpgradeFee, setCryptoUpgradeFee] = useContext(CryptoUpgradeFeeContext);
    const [CryptoWithdrawFee, setCryptoWithdrawFee] = useContext(CryptoWithdrawFeeContext);
    const [CryptoCommisionFee, setCryptoCommisionFee] = useContext(CryptoCommisionFeeContext);
    const [StockDeposit, setStockDeposit] = useContext(StockDepositContext);
    const [StockProfits, setStockProfits] = useContext(StockProfitsContext);
    const [StockTotalbal, setStockTotalbal] = useContext(StockTotalbalContext);
    const [StocksUpgradeFee, setStocksUpgradeFee] = useContext(StocksUpgradeFeeContext);
    const [StocksWithdrawFee, setStocksWithdrawFee] = useContext(StocksWithdrawFeeContext);
    const [StocksCommisionFee, setStocksCommisionFee] = useContext(StocksCommisionFeeContext);
    const [deleteUser, setDeleteUser] = useState(false);

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();
    const db = getFirestore(app);

    useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
            //   const uid = user.uid;
              const colRef = collection(db, 'users');
              const q = query(colRef, orderBy("name", "asc"))
              onSnapshot(q,(snapshot)=>{
                const usersData = [];
                snapshot.docs.forEach((doc)=>{
                    usersData.push({id:doc.id, ...doc.data()})
                });
                setUsersData(usersData);
              })
            }else{

            }
        });
    },[page, auth, db]);

    const handleEditClick = (e) => {
        setEditUser(true);
        const parent = e.target.parentElement;
        const parentParent = parent.parentElement;
        const parentParentParent = parentParent.parentElement;
        const elements = parentParentParent.children;
        setDocid(parentParentParent.id);
        const nameGt = elements[0].innerHTML;
        const mailGt = elements[1].innerHTML;
        const countryGt = elements[2].innerHTML;
        const phoneGt = elements[3].innerHTML;
        const passwordGt = elements[4].innerHTML;
        const planGt = elements[5].innerHTML;
        const ftDepoGt = elements[6].innerHTML;
        const ftProGt = elements[7].innerHTML;
        const ftTbGt = elements[8].innerHTML;
        const ftUfGt = elements[9].innerHTML;
        const ftWfGt = elements[10].innerHTML;
        const ftCfGt = elements[11].innerHTML;
        const fxDepoGt = elements[12].innerHTML;
        const fxProGt = elements[13].innerHTML;
        const fxTbGt = elements[14].innerHTML;
        const fxUfGt = elements[15].innerHTML;
        const fxWfGt = elements[16].innerHTML;
        const fxCfGt = elements[17].innerHTML;
        const cDepoGt = elements[18].innerHTML;
        const cProGt = elements[19].innerHTML;
        const cTbGt = elements[20].innerHTML;
        const cUfGt = elements[21].innerHTML;
        const cWfGt = elements[22].innerHTML;
        const cCfGt = elements[23].innerHTML;
        const sDepoGt = elements[24].innerHTML;
        const sProGt = elements[25].innerHTML;
        const sTbGt = elements[26].innerHTML;
        const sUfGt = elements[27].innerHTML;
        const sWfGt = elements[28].innerHTML;
        const SCfGt = elements[29].innerHTML;
        setName(nameGt); 
        setMail(mailGt);
        setCountry(countryGt);
        setPhone(phoneGt);
        setPassword(passwordGt);
        setPlan(planGt);
        setFeatDeposit(ftDepoGt);
        setFeatProfits(ftProGt);
        setFeatTotalbal(ftTbGt);
        setFeatUpgradeFee(ftUfGt);
        setFeatWithdrawFee(ftWfGt);
        setFeatCommisionFee(ftCfGt);
        setForexDeposit(fxDepoGt);
        setForexProfits(fxProGt);
        setForexTotalbal(fxTbGt);
        setForexUpgradeFee(fxUfGt);
        setForexWithdrawFee(fxWfGt);
        setForexCommisionFee(fxCfGt);
        setCryptoDeposit(cDepoGt);
        setCryptoProfits(cProGt);
        setCryptoTotalbal(cTbGt);
        setCryptoUpgradeFee(cUfGt);
        setCryptoWithdrawFee(cWfGt);
        setCryptoCommisionFee(cCfGt);
        setStockDeposit(sDepoGt);
        setStockProfits(sProGt);
        setStockTotalbal(sTbGt);
        setStocksUpgradeFee(sUfGt);
        setStocksWithdrawFee(sWfGt);
        setStocksCommisionFee(SCfGt);
    };

    const handleUpdate = (e)=>{
        const docRef = doc(db, "users", docid);
        const newData = {
            plan:Plan,
            CryptoWithdrawFee : CryptoWithdrawFee,
            CryptoCommisionFee : CryptoCommisionFee,
            CryptoUpgradeFee: CryptoUpgradeFee,
            StocksWithdrawFee : StocksWithdrawFee,
            StocksCommisionFee : StocksCommisionFee,
            StocksUpgradeFee: StocksUpgradeFee,
            ForexWithdrawFee : ForexWithdrawFee,
            ForexCommisionFee : ForexCommisionFee,
            ForexUpgradeFee: ForexUpgradeFee,
            FeatWithdrawFee : FeatWithdrawFee,
            FeatCommisionFee : FeatCommisionFee,
            FeatUpgradeFee: FeatUpgradeFee,
            CryptoTotalbal: CryptoTotalbal,
            CryptoDeposit: CryptoDeposit,
            CryptoProfits: CryptoProfits,
            ForexTotalbal: ForexTotalbal,
            ForexDeposit: ForexDeposit,
            ForexProfits: ForexProfits,
            StockTotalbal: StockTotalbal,
            StockDeposit: StockDeposit,
            StockProfits: StockProfits,
            FeatTotalbal: FeatTotalbal,
            FeatDeposit: FeatDeposit,
            FeatProfits: FeatProfits,
        };
        updateDoc(docRef, newData)
        .then(()=>{
            alert('Updated');
            setEditUser(false);
        })
        .catch((error)=>{
            alert(error)
        })
    };
    const handleDeleteClick = (e)=>{
        setDeleteUser(true)
        const parent = e.target.parentElement;
        const parentParent = parent.parentElement;
        const parentParentParent = parentParent.parentElement;
        setDocid(parentParentParent.id);
    };
    const handleDelete = (e)=>{
        deleteDoc(doc(db, "users", docid))
        .then(()=>{
            alert('Deleted');
            setDeleteUser(false)
        })
        .catch((error)=>{
            alert(error)
        })
    };

    const [filter, setFilter] = useState('');
    useEffect(() => {
        const filterII = filter.toUpperCase();
        const tr = document.getElementsByTagName('tr');
        for (let i = 0; i < tr.length; i++) {
          const td = tr[i].getElementsByTagName('td')[0];
          if (td) {
            const textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filterII) > -1) {
              tr[i].style.display = '';
            } else {
              tr[i].style.display = 'none';
            }
          }
        }
      }, [filter]);

      const handleSearch = (e) => {
        setFilter(e.target.value);
      }

    const [UsersData, setUsersData] = useState([]);
    return ( 
        <div>
            { editUser && <div className=" w-full h-[100vh] fixed z-[10000] px-5 top-0 left-0 bg-[rgba(0,0,0,0.7)] flex justify-center items-center overflow-auto">
                <div className=" overflow-auto w-full h-full flex justify-center items-center">
                <div className="w-full md:w-[500px] h-[80vh] rounded-md shadow p-5 bg-[#1e1e1e] relative flex justify-center flex-col text-sm font-Anuphan font-medium items-center space-y-3">
                    <div className="">
                        <p className="text-center mt-6 text-base">User Info</p>
                        <img src={plus} onClick={()=>{setEditUser(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    </div>
                    <div className=" w-full flex flex-col items-center  h-full scrollbar overflow-auto">
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            Name
                            <input readOnly value={Name} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            Mail
                            <input readOnly value={Mail} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            Country
                            <input readOnly value={Country} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            Phone
                            <input readOnly value={Phone} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            Password
                            <input readOnly value={Password} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            Plan
                            <select type="text" defaultValue={Plan} onChange={(e)=>{setPlan(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                                <option value="Starter-plan">Starter-plan</option>
                                <option value="Master-plan">Master-plan</option>
                                <option value="Premium-plan">Premium-plan</option>
                                <option value="Vip-plan">Vip-plan</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            FeatDeposit
                            <input type="text" defaultValue={FeatDeposit} onChange={(e)=>{setFeatDeposit(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            FeatProfits
                            <input type="text" defaultValue={FeatProfits} onChange={(e)=>{setFeatProfits(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            FeatTotalbal
                            <input type="text"defaultValue={FeatTotalbal} onChange={(e)=>{setFeatTotalbal(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            FeatUpgradeFee
                            <select type="text" defaultValue={FeatUpgradeFee} onChange={(e)=>{setFeatUpgradeFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" >
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            FeatWithdrawFee
                            <select type="text" defaultValue={FeatWithdrawFee} onChange={(e)=>{setFeatWithdrawFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" >
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            FeatCommisionFee
                            <select type="text" defaultValue={FeatCommisionFee} onChange={(e)=>{setFeatCommisionFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            ForexDeposit
                            <input type="text" defaultValue={ForexDeposit} onChange={(e)=>{setForexDeposit(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            ForexProfits
                            <input type="text" defaultValue={ForexProfits} onChange={(e)=>{setForexProfits(e.target.value)}}className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                            ForexTotalbal
                            <input type="text" defaultValue={ForexTotalbal} onChange={(e)=>{setForexTotalbal(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        ForexUpgradeFee
                            <select type="text" defaultValue={ForexUpgradeFee} onChange={(e)=>{setForexUpgradeFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" >
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        ForexWithdrawFee
                            <select type="text" defaultValue={ForexWithdrawFee} onChange={(e)=>{setForexWithdrawFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        ForexCommisionFee
                            <select type="text" defaultValue={ForexCommisionFee} onChange={(e)=>{setForexCommisionFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        CryptoDeposit
                            <input type="text" defaultValue={CryptoDeposit} onChange={(e)=>{setCryptoDeposit(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        CryptoProfits
                            <input type="text" defaultValue={CryptoProfits} onChange={(e)=>{setCryptoProfits(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        CryptoTotalbal
                            <input type="text" defaultValue={CryptoTotalbal} onChange={(e)=>{setCryptoTotalbal(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        CryptoUpgradeFee
                            <select type="text" defaultValue={CryptoUpgradeFee} onChange={(e)=>{setCryptoUpgradeFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                            <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        CryptoWithdrawFee
                            <select type="text" defaultValue={CryptoWithdrawFee}onChange={(e)=>{setCryptoWithdrawFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                                <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        CryptoCommisionFee
                            <select type="text" defaultValue={CryptoCommisionFee} onChange={(e)=>{setCryptoCommisionFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                            <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        StockDeposit
                            <input type="text" defaultValue={StockDeposit}onChange={(e)=>{setStockDeposit(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        StockProfits
                            <input type="text" defaultValue={StockProfits} onChange={(e)=>{setStockProfits(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        StockTotalbal
                            <input type="text" defaultValue={StockTotalbal} onChange={(e)=>{setStockTotalbal(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        StocksUpgradeFee
                            <select type="text" defaultValue={StocksUpgradeFee} onChange={(e)=>{setStocksUpgradeFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                            <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        StocksWithdrawFee
                            <select type="text" defaultValue={StocksWithdrawFee} onChange={(e)=>{setStocksWithdrawFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                            <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                        <span className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        StocksCommisionFee
                            <select type="text" defaultValue={StocksCommisionFee} onChange={(e)=>{setStocksCommisionFee(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                            <option value="false">false</option>
                                <option value="true">true</option>
                            </select>
                        </span>
                    </div>
                    <div className=" flex flex-row items-center space-x-3">
                        <button onClick={()=>{setEditUser(false)}} className="w-16 py-1 rounded-md bg-red-700">Cancel</button>
                        <button onClick={handleUpdate} className="w-16 py-1 rounded-md bg-[#2A49F9]">Update</button>
                    </div>
                </div>
                </div>
            </div>}
            { deleteUser && <div className=" w-full h-[100vh] fixed z-[10000] px-5 top-0 left-0 bg-[rgba(0,0,0,0.7)] flex justify-center items-center">
                <div className="w-full md:w-[400px] rounded-md shadow p-5 bg-[#1e1e1e] relative flex justify-center flex-col text-sm font-Anuphan font-medium items-center">
                    <p className=" text-base font-semibold text-[#a7a7a7]">Are you Sure you Want to delete this User?</p>
                    <span className=" flex flex-row space-x-3 mt-6">
                        <button onClick={()=>{setDeleteUser(false)}} className="w-16 py-1 rounded-md bg-gray-500">Cancel</button>
                        <button onClick={handleDelete} className="w-16 py-1 rounded-md bg-red-700">Delete</button>
                    </span>
                </div>
            </div>}
            <div className=" absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
                <span className=' flex flex-col font-Anuphan text-sm w-full md:w-[310px] font-medium text-[#a7a7a7] relative'>
                    <input id="search" value={filter} placeholder="Search by name..." onChange={ handleSearch } type="text" className='w-full h-[40px] p-3 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-md' />
                    {/* <button className=' px-3 h-[40px] bg-[#2A49F9] text-white absolute right-0 top-[28px] rounded-r-md text-sm'>Search</button> */}
                </span>
                <div className="bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 rounded-md shadow overflow-auto">
                <table className=" border-collapse text-center border-b w-full border-[rgba(255,255,255,0.2)] mt-3">
                    <thead>
                        <tr className=" text-[#a7a7a7] text-sm font-Anuphan font-medium">
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Name</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Mail</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Country</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Phone</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Password</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Plan</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">FeatDeposit</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">FeatProfits</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">FeatTotalbal</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">FeatUpgradeFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">FeatWithdrawFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">FeatCommisionFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ForexDeposit</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ForexProfits</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ForexTotalbal</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ForexUpgradeFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ForexWithdrawFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ForexCommisionFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">CryptoDeposit</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">CryptoProfits</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">CryptoTotalbal</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">CryptoUpgradeFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">CryptoWithdrawFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">CryptoCommisionFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">StockDeposit</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">StockProfits</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">StockTotalbal</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">StocksUpgradeFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">StocksWithdrawFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">StocksCommisionFee</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Control Center</th>
                        </tr>
                    </thead>
                    <tbody id="table">
                        {UsersData.map((doc) => {
                            return (
                                <tr key={doc.id} id={doc.id} className="text-[#fff] space-x-5 text-sm font-Anuphan font-medium">
                                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">{doc.name}</td>
                                <td className="p-2 border-b capitalize  border-[rgba(255,255,255,0.2)]">{doc.mail}</td>
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">{doc.country}</td>
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">{doc.phone}</td>
                                <td className="p-2 border-b text-white border-[rgba(255,255,255,0.2)]">{doc.password}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.plan}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.FeatDeposit}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.FeatProfits}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.FeatTotalbal}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.FeatUpgradeFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.FeatWithdrawFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.FeatCommisionFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.ForexDeposit}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.ForexProfits}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.ForexTotalbal}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.ForexUpgradeFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.ForexWithdrawFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.ForexCommisionFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.CryptoDeposit}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.CryptoProfits}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.CryptoTotalbal}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.CryptoUpgradeFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.CryptoWithdrawFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.CryptoCommisionFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.StockDeposit}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.StockProfits}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.StockTotalbal}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.StocksUpgradeFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.StocksWithdrawFee}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.StocksCommisionFee}</td>
                                <td className="p-2 border-b flex text-white capitalize border-transparent justify-center items-center">
                                    <span className=" flex flex-row space-x-3 mt-2 justify-center items-center">
                                        <button onClick={handleEditClick} className="w-16 py-1 rounded-md bg-[#2A49F9]">Edit</button>
                                        <button onClick={handleDeleteClick} className="w-16 py-1 rounded-md bg-red-700">Delete</button>
                                    </span>
                                </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
     );
}
 
export default UserDash;