import logo from '../images/logo.png';
import udl from '../images/udl.png';
import jdl from '../images/jdl.png';
import load from '../images/load.gif';
import { motion } from 'framer-motion';
// import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app'; 
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged  } from "firebase/auth";

const Signin = () => {

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
  authDomain: "algoflare.firebaseapp.com",
  projectId: "algoflare",
  storageBucket: "algoflare.appspot.com",
  messagingSenderId: "263253915028",
  appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
  measurementId: "G-5SXDQ31257"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Navigate = useNavigate();

const [mail, setMail] = useState('');
const [password, setPassword] = useState('');
const [loadValue, setLoadValue] = useState('No');

const signInBtn = ()=>{
    setLoadValue('Yes');

    signInWithEmailAndPassword(auth, mail, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      setLoadValue('No')
    })
    .catch((error) => {
      const errorCode = error.code;
      // const errorMessage = error.message;
      const mess = document.getElementById('logMessage');
      mess.innerHTML = errorCode;
      setLoadValue('No');
    });
};

useEffect(()=>{
  onAuthStateChanged(auth, (user) =>{
    if (user){
      const uid = user.uid;
      // direct to dashboard
      Navigate('/Dashboard')
    }else{

    }
  })
});

const validateMail =()=>{
  const mail = document.getElementById('mail').value;
  const mailWarn = document.getElementById('mailWarn');
  const checkMail = (mail) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(mail).toLowerCase());
  }
  if (checkMail(mail)) {
    mailWarn.classList.replace('hidden', 'block');
    mailWarn.innerHTML='Valid Mail';
    mailWarn.style.color='green';
    setMail(mail);
  } else {
    mailWarn.classList.replace('hidden', 'block');
    mailWarn.innerHTML='Invalid Mail';
    mailWarn.style.color='red';
  }
  
};

  const handlePword = (e)=>{
    const pword = e.target.value;
    setPassword(pword)
  };

    return ( 
        <motion.div
        initial={{x:100, opacity:0}}
        animate={{x:0, opacity:1}}
        exit={{x:-100, opacity:0}}
        transition={{type:'spring', stiffness:80, duration:0.2}}
        >
        <img src={ udl } className=' fixed top-0 left-0 -z-[99999] w-[200px] h-[200px] md:w-[250px] md:h-[250px] blur-2xl backdrop-blur-xl' alt="" />
        <img src={ jdl } className=' fixed bottom-0 right-0 -z-[99999] w-[200px] h-[200px] md:w-[250px] md:h-[250px] blur-2xl backdrop-blur-xl' alt="" />
        <div className=" w-full h-[100vh] flex justify-center items-center p-5 md:p-10">
          <div className="w-full md:w-[350px] h-[100vh] flex justify-center items-center space-y-3 flex-col">
            <span className="flex flex-row items-center space-x-2">
              <img src={ logo } alt="" />
              <p className=" text-base font-normal font-Bruno">Alpha</p>
            </span>
            <p className=" font-Anuphan text-xl md:text-2xl font-bold ">Log in</p>
            <p className=" font-Anupan text-base font-normal capitalize mb-5 text-[rgba(255,255,255,0.8)]">Welcome back!</p>
            <div className=" space-y-4 w-full flex flex-col justify-center items-center">
              <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                Email
                <input type="text" onInput={ validateMail } id="mail" className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" placeholder="Enter Your Mail" />
                <p id='mailWarn' className=" capitalize hidden mr-auto text-xs mt-1 text-[rgba(255,255,255,0.9)]">Please fill in your Email</p>
              </label>
              <label className=" w-full flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                Password
                <input type="password" onInput={ handlePword } className=" w-full md:w-[350px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1" placeholder="*******" />
              </label>
              <p id='logMessage' className="block mr-auto text-[#a7a7a7] font-Anuphan -mt-5 text-sm"></p>
              <button id="signIn" onClick={ signInBtn } className=" w-full md:w-[350px] h-[44px] flex justify-center items-center bg-[#2A49F9] text-base font-normal font-Anuphan rounded-md ">
                { loadValue === 'No' && 'Sign In' }
                { loadValue === 'Yes' && <img src={ load } className=' w-6 h-6' alt="" />}
              </button>
            </div>
          </div>
        </div>
        </motion.div>
     );
}
 
export default Signin;
<>
</>