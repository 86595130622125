import { useContext } from 'react';
import { PageContext } from '../contexts/contextProvider';
import users from '../images/overview.png';
import transaction from '../images/transaction.png';
import receipt from '../images/receipt.svg';
import pop from '../images/discount.png';
import wallet from '../images/upup.png';
import logo from '../images/logo.png';
import logout from '../images/logout.png';
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const SideBarDash = () => {
    const Navigate = useNavigate();
    // Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    const logOut = ()=>{
        signOut(auth).then(() => {
            Navigate('/')
            }).catch((error) => {
            console.log('An error happened');
        });
    };

    const [page, setPage] = useContext(PageContext);
    return ( 
        <>
         <div className="">
            <span className=' ml-10 md:ml-0 flex flex-row  items-center space-x-2'>
                <img src={ logo } alt="" />
                <p className=' font-Bruno text-base md:hidden lg:block tracking-wide font-normal'>Alpha</p>
            </span>
            <div className=' mt-10 text-[#a7a7a7] text-sm font-Anuphan space-y-5'>
                <button onClick={()=>{setPage('Users')}} className=' flex flex-row items-center w-full space-x-2 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ users } alt="" />
                    <p className='md:hidden lg:block'>Users</p>
                </button>
                <button onClick={()=>{setPage('Proof Of Payment')}}  className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ pop } alt="" />
                    <p className='md:hidden lg:block'>POP</p>
                </button>
                <button onClick={()=>{setPage('Transactions')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ transaction } alt="" />
                    <p className='md:hidden lg:block'>Transactions</p>
                </button>
                <button onClick={()=>{setPage('Wallets')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ wallet } alt="" />
                    <p className='md:hidden lg:block'>Wallets</p>
                </button>
                <button onClick={()=>{setPage('Payment Method')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ receipt } alt="" />
                    <p className='md:hidden lg:block'>Payment Method</p>
                </button>
                <button onClick={()=>{setPage('Verification')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ wallet } alt="" />
                    <p className='md:hidden lg:block'>Verification</p>
                </button>
                <button onClick={()=>{setPage('Trades')}} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ transaction } alt="" />
                    <p className='md:hidden lg:block'>Trades</p>
                </button>
                <button id='logout' onClick={logOut} className=' flex flex-row items-center w-full space-x-2 hover:bg-[rgba(78,78,97,0.2)] focus:bg-[rgba(78,78,97,0.2)] active:bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] px-3 py-3 font-normal shadow-sm rounded-md '>
                    <img src={ logout } alt="" />
                    <p className='md:hidden lg:block'>Logout</p>
                </button>
            </div>
        </div>
        </>
     );
}
 
export default SideBarDash;