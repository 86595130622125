import {  useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { PageContext } from "../contexts/contextProvider";

const Pop = () => {

    const [page, setPage] = useContext(PageContext); 

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };
      
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();
    const db = getFirestore(app);

    useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
            //   const uid = user.uid;
              const colRef = collection(db, 'transactions');
              const q = query(colRef, orderBy("date", "desc"))
              onSnapshot(q,(snapshot)=>{
                const transactions = [];
                snapshot.docs.forEach((doc)=>{
                    transactions.push({id:doc.id, ...doc.data()})
                });
                setTransactionData(transactions);
              })
            }else{

            }
        });
    },[page, auth, db]);

    const [transactionData, setTransactionData] = useState([]);

    return ( 
        <>
        <div className="absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
            <div className=" flex justify-around items-center flex-wrap">
                {transactionData.map((doc) =>{
                    return(
                    <div key={doc.id} id={doc.id} className=" w-full md:w-[350px] h-[500px] md:h-[500px] bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-md shadow mb-8 relative">
                        <div className=" w-full h-[390px] p-3">
                            <img src={doc.url} className="w-full h-[390px]" alt="" />
                        </div>
                        <span className=" block absolute bottom-0 px-2 mb-2 h-[60px] w-full">
                            <p className="text-[#fff] text-sm font-Anuphan font-medium">Name: {doc.name}</p>
                            <p className="text-[#fff] text-sm font-Anuphan font-medium">Date: {doc.date}</p>
                            <p className="text-[#fff] text-sm font-Anuphan font-medium">Time: {doc.time}</p>
                        </span>
                    </div>
                    )
                })}        
            </div>
        </div>
        </>
     );
}
 
export default Pop;