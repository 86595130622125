import { createContext, useEffect, useState } from "react";

export const PageContext = createContext();
export const DocidContext = createContext();
export const NameContext = createContext();
export const MailContext = createContext();
export const CountryContext = createContext();
export const PhoneContext = createContext();
export const PasswordContext = createContext();
export const PlanContext = createContext();
export const FeatDepositContext = createContext();
export const FeatProfitsContext = createContext();
export const FeatTotalbalContext = createContext();
export const FeatUpgradeFeeContext = createContext();
export const FeatWithdrawFeeContext = createContext();
export const FeatCommisionFeeContext = createContext();
export const ForexDepositContext = createContext();
export const ForexProfitsContext = createContext();
export const ForexTotalbalContext = createContext();
export const ForexUpgradeFeeContext = createContext();
export const ForexWithdrawFeeContext = createContext();
export const ForexCommisionFeeContext = createContext();
export const CryptoDepositContext = createContext();
export const CryptoProfitsContext = createContext();
export const CryptoTotalbalContext = createContext();
export const CryptoUpgradeFeeContext = createContext();
export const CryptoWithdrawFeeContext = createContext();
export const CryptoCommisionFeeContext = createContext();
export const StockDepositContext = createContext();
export const StockProfitsContext = createContext();
export const StockTotalbalContext = createContext();
export const StocksUpgradeFeeContext = createContext();
export const StocksWithdrawFeeContext = createContext();
export const StocksCommisionFeeContext = createContext();
export const EditUserContext = createContext();


const ContextProvider = (props) => {
    const [editUser, setEditUser] = useState(false);
    const [page, setPage] = useState('Users');
    const [docid, setDocid] = useState('');
    const [Name, setName] = useState('');
    const [Mail, setMail] = useState('');
    const [Country, setCountry] = useState('');
    const [Phone, setPhone] = useState('');
    const [Password, setPassword] = useState('');
    const [Plan, setPlan] = useState('');
    const [FeatDeposit, setFeatDeposit] = useState('');
    const [FeatProfits, setFeatProfits] = useState('');
    const [FeatTotalbal, setFeatTotalbal] = useState('');
    const [FeatUpgradeFee, setFeatUpgradeFee] = useState('');
    const [FeatWithdrawFee, setFeatWithdrawFee] = useState('');
    const [FeatCommisionFee, setFeatCommisionFee] = useState('');
    const [ForexDeposit, setForexDeposit] = useState('');
    const [ForexProfits, setForexProfits] = useState('');
    const [ForexTotalbal, setForexTotalbal] = useState('');
    const [ForexUpgradeFee, setForexUpgradeFee] = useState('');
    const [ForexWithdrawFee, setForexWithdrawFee] = useState('');
    const [ForexCommisionFee, setForexCommisionFee] = useState('');
    const [CryptoDeposit, setCryptoDeposit] = useState('');
    const [CryptoProfits, setCryptoProfits] = useState('');
    const [CryptoTotalbal, setCryptoTotalbal] = useState('');
    const [CryptoUpgradeFee, setCryptoUpgradeFee] = useState('');
    const [CryptoWithdrawFee, setCryptoWithdrawFee] = useState('');
    const [CryptoCommisionFee, setCryptoCommisionFee] = useState('');
    const [StockDeposit, setStockDeposit] = useState('');
    const [StockProfits, setStockProfits] = useState('');
    const [StockTotalbal, setStockTotalbal] = useState('');
    const [StocksUpgradeFee, setStocksUpgradeFee] = useState('');
    const [StocksWithdrawFee, setStocksWithdrawFee] = useState('');
    const [StocksCommisionFee, setStocksCommisionFee] = useState('');

    return (
        <EditUserContext.Provider value={[editUser, setEditUser]}>
        <StocksCommisionFeeContext.Provider value={[StocksCommisionFee, setStocksCommisionFee]}>
        <StocksWithdrawFeeContext.Provider value={[StocksWithdrawFee, setStocksWithdrawFee]}>
        <StocksUpgradeFeeContext.Provider value={[StocksUpgradeFee, setStocksUpgradeFee]}>
        <StockTotalbalContext.Provider value={[StockTotalbal, setStockTotalbal]}>
        <StockProfitsContext.Provider value={[StockProfits, setStockProfits]}>
        <StockDepositContext.Provider value={[StockDeposit, setStockDeposit]}>
        <CryptoCommisionFeeContext.Provider value={[CryptoCommisionFee, setCryptoCommisionFee]}>
        <CryptoWithdrawFeeContext.Provider value={[CryptoWithdrawFee, setCryptoWithdrawFee]}>
        <CryptoUpgradeFeeContext.Provider value={[CryptoUpgradeFee, setCryptoUpgradeFee]}>
        <CryptoTotalbalContext.Provider value={[CryptoTotalbal, setCryptoTotalbal]}>
        <CryptoProfitsContext.Provider value={[CryptoProfits, setCryptoProfits]}>
        <CryptoDepositContext.Provider value={[CryptoDeposit, setCryptoDeposit]}>
        <ForexCommisionFeeContext.Provider value={[ForexCommisionFee, setForexCommisionFee]}>
        <ForexWithdrawFeeContext.Provider value={[ForexWithdrawFee, setForexWithdrawFee]}>
            <ForexUpgradeFeeContext.Provider value={[ForexUpgradeFee, setForexUpgradeFee]}>
                <ForexTotalbalContext.Provider value={[ForexTotalbal, setForexTotalbal]}>
                    <ForexProfitsContext.Provider value={[ForexProfits, setForexProfits]}>
                        <ForexDepositContext.Provider value={[ForexDeposit, setForexDeposit]}>
                            <FeatCommisionFeeContext.Provider value={[FeatCommisionFee, setFeatCommisionFee]}>
                                <FeatWithdrawFeeContext.Provider value={[FeatWithdrawFee, setFeatWithdrawFee]}>
                                    <FeatUpgradeFeeContext.Provider value={[FeatUpgradeFee, setFeatUpgradeFee]}>
                                        <FeatTotalbalContext.Provider value={[FeatTotalbal, setFeatTotalbal]}>
                                            <FeatProfitsContext.Provider value={[FeatProfits, setFeatProfits]}>
                                                <FeatDepositContext.Provider value={[FeatDeposit, setFeatDeposit]}>
                                                    <PlanContext.Provider value={[Plan, setPlan]}>
                                                        <PasswordContext.Provider value={[Password, setPassword]}>
                                                            <PhoneContext.Provider value={[Phone, setPhone]}>
                                                                <CountryContext.Provider value={[Country, setCountry]}>
                                                                    <MailContext.Provider value={[Mail, setMail]}>
                                                                        <NameContext.Provider value={[Name, setName]}>
                                                                            <DocidContext.Provider value={[docid, setDocid]}>
                                                                                <PageContext.Provider value={[page, setPage]}>
                                                                                    {props.children}
                                                                                </PageContext.Provider>
                                                                            </DocidContext.Provider>
                                                                        </NameContext.Provider>
                                                                    </MailContext.Provider>
                                                                </CountryContext.Provider>
                                                            </PhoneContext.Provider>
                                                        </PasswordContext.Provider>
                                                    </PlanContext.Provider>
                                                </FeatDepositContext.Provider>
                                            </FeatProfitsContext.Provider>
                                        </FeatTotalbalContext.Provider>
                                    </FeatUpgradeFeeContext.Provider>
                                </FeatWithdrawFeeContext.Provider>
                            </FeatCommisionFeeContext.Provider>
                        </ForexDepositContext.Provider>
                    </ForexProfitsContext.Provider>
                </ForexTotalbalContext.Provider>
            </ForexUpgradeFeeContext.Provider>
        </ForexWithdrawFeeContext.Provider>
        </ForexCommisionFeeContext.Provider>
        </CryptoDepositContext.Provider>
        </CryptoProfitsContext.Provider>
        </CryptoTotalbalContext.Provider>
        </CryptoUpgradeFeeContext.Provider>
        </CryptoWithdrawFeeContext.Provider>
        </CryptoCommisionFeeContext.Provider>
        </StockDepositContext.Provider>
        </StockProfitsContext.Provider>
        </StockTotalbalContext.Provider>
        </StocksUpgradeFeeContext.Provider>
        </StocksWithdrawFeeContext.Provider>
        </StocksCommisionFeeContext.Provider>
        </EditUserContext.Provider>
     );
}
 
export default ContextProvider;
