import { useContext, useEffect } from "react";
import { PageContext } from "../contexts/contextProvider";
import { motion } from "framer-motion";
import menu from '../images/menu.png';
import jdl from '../images/jdl.png';
import Headerdash from "../comps/headerDash";
import SideBarDash from "../comps/sideBarDash";
import UserDash from "../comps/userDash";
import Transactions from "../comps/transactionDash";
import Pop from "../comps/pop";
import Wallet from "../comps/wallet";
import PayMeth from "../comps/paymeth";
import Verification from "../comps/verification";
import Trades from "../comps/trades";


const Dashboard = () => {
    const [page, setPage] = useContext(PageContext);
    
    const menuClick = ()=>{
        const overlay = document.getElementById('overlay');

        const isClose = overlay.classList.contains('-translate-x-[100%]');
            if (isClose) {
                overlay.classList.remove('-translate-x-[100%]');
            } else {
                overlay.classList.add('-translate-x-[100%]');
            }
    };
    
    useEffect(()=>{
        const userP = document.getElementById('user');
        const transactions = document.getElementById('Transactions');
        const pop = document.getElementById('POP');
        const wallet = document.getElementById('Wallets');
        const pm = document.getElementById('pm');
        const veri = document.getElementById('veri');
        const trade = document.getElementById('trade');

        if(page === 'Users'){
            userP.style.display = 'block';
            transactions.style.display = 'none';
            pop.style.display = 'none';
            wallet.style.display = 'none';
            pm.style.display = 'none';
            veri.style.display = 'none';
            trade.style.display = 'none';
        }
        else if(page === 'Transactions'){
            userP.style.display = 'none';
            transactions.style.display = 'block';
            pop.style.display = 'none';
            wallet.style.display = 'none';
            pm.style.display = 'none';
            veri.style.display = 'none';
            trade.style.display = 'none';
        }
        else if(page === 'Proof Of Payment'){
            userP.style.display = 'none';
            transactions.style.display = 'none';
            pop.style.display = 'block';
            wallet.style.display = 'none';
            pm.style.display = 'none';
            veri.style.display = 'none';
            trade.style.display = 'none';
        }
        else if(page === 'Wallets'){
            userP.style.display = 'none';
            transactions.style.display = 'none';
            pop.style.display = 'none';
            wallet.style.display = 'block';
            pm.style.display = 'none';
            veri.style.display = 'none';
            trade.style.display = 'none';
        }
        else if(page === 'Payment Method'){
            userP.style.display = 'none';
            transactions.style.display = 'none';
            pop.style.display = 'none';
            wallet.style.display = 'none';
            pm.style.display = 'block';
            veri.style.display = 'none';
            trade.style.display = 'none';
        }
        else if(page === 'Verification'){
            userP.style.display = 'none';
            transactions.style.display = 'none';
            pop.style.display = 'none';
            wallet.style.display = 'none';
            pm.style.display = 'none';
            veri.style.display = 'block';
            trade.style.display = 'none';
        }
        else if(page === 'Trades'){
            userP.style.display = 'none';
            transactions.style.display = 'none';
            pop.style.display = 'none';
            wallet.style.display = 'none';
            pm.style.display = 'none';
            veri.style.display = 'none';
            trade.style.display = 'block';
        }
    });
    


    useEffect(()=>{
        const overlay = document.getElementById('overlay');
        overlay.classList.add('-translate-x-[100%]');
    },[page])

    return ( 
        <motion.div 
        initial={{x:100, opacity:0}}
        animate={{x:0, opacity:1}}
        exit={{x:-100, opacity:0}}
        transition={{type:'spring', stiffness:80, duration:0.2}}
         className=" w-full">
            <img src={ menu } onClick={ menuClick } id="menu" className=" block md:hidden absolute z-[9999] top-5 left-3 w-6 h-6" alt="" />
            <img src={ jdl } className=' fixed bottom-0 right-0 -z-[99999] w-[200px] h-[200px] md:w-[250px] md:h-[250px] blur-2xl backdrop-blur-xl' alt="" />
            <Headerdash/>
            <div id="overlay" className='-translate-x-[100%]  md:-translate-x-0 transition-all duration-500 w-[80%] md:w-[80px] lg:w-1/5 border-r shadow-md md:shadow-[0px] h-[100vh] py-3 px-3 md:px-5 fixed top-0 left-0 bg-[rgba(0,0,0,1)] backdrop-blur-[15px] md:backdrop-blur-none md:bg-transparent border-[rgba(255,255,255,0.2)] z-[999]'>
                <SideBarDash/>
            </div>
            <div id="user" className="">
                <UserDash/>
            </div>
            <div id="Transactions" className="">
                <Transactions/>
            </div>
            <div id="POP" className="">
                <Pop/>
            </div>
            <div id="Wallets" className="">
                <Wallet/>
            </div>
            <div id="pm" className="">
                <PayMeth/>
            </div>
            <div id="veri" className="">
                <Verification/>
            </div>
            <div id="trade" className="">
                <Trades/>
            </div>
        </motion.div>
     );
}
 
export default Dashboard;