import {  useContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, orderBy, onSnapshot, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { PageContext } from "../contexts/contextProvider";
import plus from '../images/cancel.png';



const Trades = () => {
    const [page, setPage] = useContext(PageContext); 

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const auth = getAuth();
      const db = getFirestore(app)

      useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
            //   const uid = user.uid;
              const colRef = collection(db, 'trades');
              const q = query(colRef, orderBy("date", "asc"), orderBy("time", "desc"))
              onSnapshot(q,(snapshot)=>{
                const transactions = [];
                snapshot.docs.forEach((doc)=>{
                    transactions.push({id:doc.id, ...doc.data()})
                });
                setTransactionData(transactions);
              })
            }else{

            }
        });
    },[page, auth, db]);

    
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('');
    const [date, setDate] = useState('');
    const [loss, setLoss] = useState('');
    const [profit, setProfit] = useState('');
    const [status, setStatus] = useState('');
    const [docID, setDocID] =useState('');

    
    const handleEditClick = (e)=>{
        setEditTrans(true);
        const parent = e.target.parentElement;
        const parentParent = parent.parentElement;
        const parentParentParent = parentParent.parentElement;
        const elements = parentParentParent.children;
        setDocID(parentParentParent.id);
        
        setName(elements[0].innerHTML);
        setAmount(elements[1].innerHTML);
        setType(elements[2].innerHTML);
        setDate(elements[3].innerHTML);
        setLoss(elements[4].innerHTML);
        setProfit(elements[5].innerHTML);
        setStatus(elements[6].innerHTML);
    };

    const handleUpdate = ()=>{
        const docRef = doc(db, 'trades', docID);
        const newData = {
            tradeState:status
        };
        updateDoc(docRef, newData)
        .then(()=>{
            alert('Updated');
            setEditTrans(false)
        })
        .catch((error)=>{
            alert(error)
        })
    };
    
    const handleDelete = (e) =>{
        setDeleteUser(true);
        const parent = e.target.parentElement;
        const parentParent = parent.parentElement;
        const parentParentParent = parentParent.parentElement;
        setDocID(parentParentParent.id);
    };
    const handleDeleteClick =(e)=>{
        deleteDoc(doc(db, "trades", docID))
        .then(()=>{
            alert('Deleted');
            setDeleteUser(false)
        })
        .catch((error)=>{
            alert(error)
        })
    };

    const [filter, setFilter] = useState('');
    useEffect(() => {
        const filterII = filter.toUpperCase();
        const tr = document.getElementsByTagName('tr');
        for (let i = 0; i < tr.length; i++) {
          const td = tr[i].getElementsByTagName('td')[0];
          if (td) {
            const textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filterII) > -1) {
              tr[i].style.display = '';
            } else {
              tr[i].style.display = 'none';
            }
          }
        }
    }, [filter]);

    const handleSearch = (e) => {
        setFilter(e.target.value);
    }

    const [transactionData, setTransactionData] = useState([]);
    const [editTrans, setEditTrans] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    
    return ( 
        <div>
            { deleteUser && <div className=" w-full h-[100vh] fixed z-[10000] px-5 top-0 left-0 bg-[rgba(0,0,0,0.7)] flex justify-center items-center">
                <div className="w-full md:w-[400px] rounded-md shadow p-5 bg-[#1e1e1e] relative flex justify-center flex-col text-sm font-Anuphan font-medium items-center">
                    <p className=" text-base font-semibold text-center text-[#a7a7a7]">Are you Sure you Want to delete this Transaction?</p>
                    <span className=" flex flex-row space-x-3 mt-6">
                        <button onClick={()=>{setDeleteUser(false)}} className="w-16 py-1 rounded-md bg-gray-500">Cancel</button>
                        <button onClick={handleDeleteClick} className="w-16 py-1 rounded-md bg-red-700">Delete</button>
                    </span>
                </div>
            </div>}
            { editTrans && <div className="w-full h-[100vh] fixed z-[10000] px-5 top-0 left-0 bg-[rgba(0,0,0,0.7)] flex justify-center items-center overflow-auto">
                <div className="w-full md:w-[500px] overflow-auto scrollbar rounded-md shadow p-5 bg-[#1e1e1e] relative flex justify-center flex-col text-sm font-Anuphan font-medium items-center space-y-3">
                    <p className="text-center text-base">Transaction Info</p>
                    <img src={plus} onClick={()=>{setEditTrans(false)}} className=" absolute top-3 right-4 h-6 w-6" alt="" />
                    <span className=" w-full mt-3 flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Name
                        <input readOnly defaultValue={name} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </span>
                    <span className=" w-full mt-3 flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Amount
                        <input readOnly defaultValue={amount} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </span>
                    <span className=" w-full mt-3 flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Time
                        <input readOnly defaultValue={type} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </span>
                    <span className=" w-full mt-3 flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Date
                        <input readOnly defaultValue={date} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </span>
                    <span className=" w-full mt-3 flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Loss
                        <input readOnly defaultValue={loss} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </span>
                    <span className=" w-full mt-3 flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        Profit
                        <input readOnly defaultValue={profit} type="text" className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1"  />
                    </span>
                    <span className=" w-full mt-3 flex flex-col font-Anuphan text-sm font-normal text-[rgba(255,255,255,0.8)]">
                        TradeState
                        <select type="text" defaultValue={status} onChange={(e)=>{setStatus(e.target.value)}} className=" w-full md:w-[450px] h-[44px] bg-[rgba(78,78,97,0.2)] p-2 backdrop-blur-[15px] focus:bg-[rgba(78,78,97,0.2)] focus:backdrop-blur-[15px] active:backdrop-blur-[15px] active:bg-[rgba(78,78,97,0.2)] focus:border-0 active:border-0 rounded-md mt-1">
                            <option value="loss">loss</option>
                            <option value="gain">gain</option>
                        </select>
                    </span>
                    <span className=" flex flex-row items-center space-x-3">
                        <button onClick={()=>{setEditTrans(false)}} className="w-16 py-1 rounded-md bg-red-700">Cancel</button>
                        <button onClick={handleUpdate} className="w-16 py-1 rounded-md bg-[#2A49F9]">Update</button>
                    </span>
                </div>
            </div>}
            <div className=" absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
                <span className=' flex flex-col font-Anuphan text-sm w-full md:w-[310px] font-medium text-[#a7a7a7] relative'>
                    <input id="search" value={filter} placeholder="Search by name..." onChange={ handleSearch } type="text" className='w-full h-[40px] p-3 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-md' />
                    {/* <button className=' px-3 h-[40px] bg-[#2A49F9] text-white absolute right-0 top-[28px] rounded-r-md text-sm'>Search</button> */}
                </span>
                <div className="bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 rounded-md shadow overflow-auto">
                <table className=" border-collapse text-center border-b w-full border-[rgba(255,255,255,0.2)] mt-3">
                    <thead>
                        <tr className=" text-[#a7a7a7] text-sm font-Anuphan font-medium">
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Name</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Amount</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Time</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Date</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Loss</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Profit</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">TradeState</th>
                            <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Control Center</th>
                        </tr>
                    </thead>
                    <tbody id="table">
                        {transactionData.map((doc) => {
                            return (
                                <tr key={doc.id} id={doc.id} className="text-[#fff] text-sm font-Anuphan font-medium">
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">{ doc.name}</td>
                                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">{'$' + doc.amount}</td>
                                <td className="p-2 border-b capitalize  border-[rgba(255,255,255,0.2)]">{doc.time}</td>
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">{doc.date}</td>
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">{doc.loss}</td>
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">{doc.profit}</td>
                                <td className="p-2 border-b text-white capitalize border-[rgba(255,255,255,0.2)]">{doc.tradeState}</td>
                                <td className="p-2 border-b flex text-white capitalize border-[rgba(255,255,255,0.2)] justify-center items-center">
                                    <span className=" flex flex-row space-x-3 mt- justify-center items-center">
                                        <button onClick={handleEditClick} className="w-16 py-1 rounded-md bg-[#2A49F9]">Edit</button>
                                        <button onClick={handleDelete} className="w-16 py-1 rounded-md bg-red-700">Delete</button>
                                    </span>
                                </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
     );
}
 
export default Trades;