import {  useContext, useEffect, useState } from "react";
import { PageContext } from "../contexts/contextProvider";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const Verification = () => {

    const [page, setPage] = useContext(PageContext); 
    const [payVerificationData, setVerificationData] = useState([]);

    const firebaseConfig = {
        apiKey: "AIzaSyAQ90uvkSS-DMFMrtvjpFPASM9pcAh6ms0",
        authDomain: "algoflare.firebaseapp.com",
        projectId: "algoflare",
        storageBucket: "algoflare.appspot.com",
        messagingSenderId: "263253915028",
        appId: "1:263253915028:web:85b54ee162c2f4053dcb81",
        measurementId: "G-5SXDQ31257"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();
    const db = getFirestore(app);

    useEffect(()=>{
        onAuthStateChanged(auth, (user) =>{
            if (user){
            //   const uid = user.uid;
              const colRef = collection(db, 'verification');
              const q = query(colRef, orderBy("name", "desc"))
              onSnapshot(q,(snapshot)=>{
                const verification = [];
                snapshot.docs.forEach((doc)=>{
                    verification.push({id:doc.id, ...doc.data()})
                });
                setVerificationData(verification);
              })
            }else{

            }
        });
    },[page, auth, db]);

    const [filter, setFilter] = useState('');
    useEffect(() => {
        const filterII = filter.toUpperCase();
        const tr = document.getElementsByTagName('tr');
        for (let i = 0; i < tr.length; i++) {
          const td = tr[i].getElementsByTagName('td')[0];
          if (td) {
            const textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filterII) > -1) {
              tr[i].style.display = '';
            } else {
              tr[i].style.display = 'none';
            }
          }
        }
    }, [filter]);

    const handleSearch = (e) => {
        setFilter(e.target.value);
    };



    return ( 
        <>
        <div className=" absolute left-0 w-full md:w-[calc(100%-80px)]  lg:translate-x-0 lg:w-[80%] md:left-[80px] lg:left-[20%] top-[100px] px-5 space-y-6 -z-[99]">
            <span className=' flex flex-col font-Anuphan text-sm w-full md:w-[310px] font-medium text-[#a7a7a7] relative'>
                <input id="search" value={filter} placeholder="Search by name..." onChange={ handleSearch } type="text" className='w-full h-[40px] p-3 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] rounded-md' />
                {/* <button className=' px-3 h-[40px] bg-[#2A49F9] text-white absolute right-0 top-[28px] rounded-r-md text-sm'>Search</button> */}
            </span>
            <div className="bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] p-3 rounded-md shadow overflow-auto">
            <table className=" border-collapse text-center border-b w-full border-[rgba(255,255,255,0.2)] mt-3">
                <thead>
                    <tr className=" text-[#a7a7a7] text-sm font-Anuphan font-medium">
                        <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Name</th>
                        <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">Date</th>
                        <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ID Type</th>
                        <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ID(Front)</th>
                        <th className="p-2 border-b border-[rgba(255,255,255,0.2)]">ID(Back)</th>
                    </tr>
                </thead>
                <tbody id="table">
                    {payVerificationData.map((doc) => {
                        return (
                            <tr key={doc.id} id={doc.id} className="text-[#fff] text-sm font-Anuphan font-medium">
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]">{ doc.name}</td>
                                <td className="p-2 border-b border-[rgba(255,255,255,0.2)]">{doc.date}</td>
                                <td className="p-2 border-b capitalize  border-[rgba(255,255,255,0.2)]">{doc.idType}</td>
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]"><a target="blank" href={doc.url1} className=" px-4 py-1 rounded-md bg-[#2A49F9]">Get IMG</a></td>
                                <td className="p-2 border-b capitalize border-[rgba(255,255,255,0.2)]"><a target="blank" href={doc.url2} className=" px-4 py-1 rounded-md bg-[#2A49F9]">Get IMG</a></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            </div>
        </div>
        </>
     );
}
 
export default Verification;