import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Signin from "./pages/signin";
import Dashboard from "./pages/dashboard";


function App() {
  const location = useLocation(); 
  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
          <Route path="/" element={<Signin />} />
          <Route path="/Dashboard" element={ <Dashboard/> } />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
